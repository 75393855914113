import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Hero from './Hero'
import "./mainpage.css";
import Section1 from './Section1';
import Howwedoit from './Howwedoit';
import Testimonial from './Testimonial';
import Footer from '../../Components/Footer/Footer';
import Doctor from './Doctor';
import Query from '../../Components/Query/Query';
import Section2 from './Section2';
import Newsletter from '../../Components/Newsletter/Newsletter';
import Video from './Video';
import Blog from './Blog';

const MainPage = () => {
  return (
    <div>
        <Navbar />
        <Hero />
        <Video />
        <Section2 />
     
        {/* <Section1 /> */}
        {/* <Howwedoit /> */}
        <Testimonial />
 
        <Doctor />
        <Query/>
        <Blog />
        <Newsletter />
        <Footer />
    </div>
  )
}

export default MainPage
