import React, { useEffect } from 'react'
import "./query.css";
import Phone_icon from "../../assets/img/button1.png"
import { Icon } from '@iconify/react';
import AvatarImage from "../../assets/images/man.png"
const Query = () => {
  
  function myFunction() {

    document.getElementById("deployform").classList.add('open');
  }

  function myFunction1() {

    document.getElementById("deployform").classList.remove('open');
  }

  return (
    <div>
      <div>

      </div>
      <section id="query">
        <div id="deployform" className="deployform">
          <img src={Phone_icon} alt="Phone_icon" className="icon" onClick={myFunction} />
            

            <i className="fa fa-times cancel" onClick={myFunction1} aria-hidden="true"></i>
            
            <form className="">
            <span> <img src={AvatarImage} />Hi, There</span>
              <input className='' type="text" placeholder="Name" required />
              <input className='' type="email" placeholder="Email" required />
              <input className='' type="text" placeholder="Phone" required />
              <textarea rows="2" placeholder="Your message" required></textarea>
              <button className='btn btn-primary'><a href="contact.html" >Talk to us today</a></button>
            </form>
           
        </div>
     
      </section>
      

    </div>
  )
}

export default Query



// element {

//   color: rgb(255, 255, 255);
//   background: linear-gradient(135deg, rgb(42, 39, 218) 0%, rgb(0, 204, 255) 100%);

// }


// .chat-header {
//   padding: 24px 28px 0px;
//   background: linear-gradient(-61deg, rgb(0, 224, 216), rgb(32, 29, 142));
//   position: relative;
//   z-index: 4;
//   flex: 0 0 auto;
// }



// element {
//   background-image: linear-gradient(135deg, rgba(42, 39, 218, 0.72) 0%, rgba(0, 204, 255, 0.72) 100%);
// }