import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

const BlockContentSerializer = ({ blocks }) => {
  const serializers = {
    marks: {
      link: ({ mark, children }) => (
        <a href={mark.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
    },
  };

  return <BlockContent blocks={blocks} serializers={serializers} />;
};

export default BlockContentSerializer;
