import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'

import Footer from '../../Components/Footer/Footer'
import Privacy from './Privacy'

import Query from '../../Components/Query/Query'

const TANDC = () => {
  return (
    <div>
        <Navbar />
      
        <Privacy />
        <Query />
  
        <Footer />
    </div>
  )
}

export default TANDC